import React from 'react';

import SEO from '../components/seo';
import Container from '../components/container/container';
import LayoutContent from '../components/layout/layout-content';

const NotFoundPage = () => (
  <LayoutContent>
    <SEO title="404: Seite nicht gefunden" />
    <Container>
      <h1>Seite nicht gefunden</h1>
      <div style={{ minHeight: '100vh' }}>
        <p>Die angeforderte Seite existiert nicht.</p>
      </div>
    </Container>
  </LayoutContent>
);

export default NotFoundPage;
